import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import icon2_1 from "../../assets/icon2_1.png";
import icon2_2 from "../../assets/icon2_2.png";
import icon2_3 from "../../assets/icon2_3.png";
import icon2_4 from "../../assets/icon2_4.png";
import icon2_5 from "../../assets/icon2_5.png";
import icon2_6 from "../../assets/icon2_6.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <h2 className="title">NOSSO DIFERENCIAL</h2>

          <div className="gallery">
            <div className="item">
              <figure className="image">
                <img
                  src={icon2_1}
                  alt="Contamos com Profissionais qualificados"
                />
              </figure>

              <p className="text">Contamos com Profissionais qualificados</p>
            </div>

            <div className="item">
              <figure className="image">
                <img
                  src={icon2_2}
                  alt="Possuimos equipamentos e produtos de alta qualidades"
                />
              </figure>

              <p className="text">
                Possuimos equipamentos e produtos de alta qualidades
              </p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon2_3} alt="Atendimento 24h, 7 dias na semana" />
              </figure>

              <p className="text">Atendimento 24h, 7 dias na semana</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon2_4} alt="Visita e orçamento gratuitos" />
              </figure>

              <p className="text">Visita e orçamento gratuitos</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon2_5} alt="Equipe Certificada pela CRQ." />
              </figure>

              <p className="text">Equipe Certificada pela CRQ.</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon2_6} alt="Garantia de qualidade e satisfação" />
              </figure>

              <p className="text">Garantia de qualidade e satisfação</p>
            </div>
          </div>
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <h2 className="title">SOLICITE JÁ O SEU ORÇAMENTO!</h2>

          <h3 className="subtitle">Fale conosco via WhatsApp ou Telefone:</h3>

          <div className="bottom">
            <CtaWpp text={"(15) 99665-0653"} />
            <CtaTel />
          </div>
        </div>
      </article>
    </section>
  );
};

export default Content;
