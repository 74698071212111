import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import logo from "../../assets/logo.png";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import icon5 from "../../assets/icon5.png";
import icon6 from "../../assets/icon6.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <CtaWpp text={"Solicite um orçamento"} />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h1 className="title">
            <p>Desentupimentos 24 HORAS</p>
            <p className="bar">|</p>
            <p>Orçamento Gratuito</p>
            <p className="bar">|</p>
            <p>Melhor Preço da Região!</p>
          </h1>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <h2 className="title">GARANTIA DE QUALIDADE E SATISFAÇÃO</h2>
          <h3 className="subtitle">
            Trabalhamos de forma objetiva, buscando fornecer os melhores
            resultados aos nossos clientes.
          </h3>

          <div className="banner">
            <figure className="logo">
              <img src={logo} alt="Logo" />
            </figure>

            <h2 className="title">
              Especialidades em Desentupimento E DEDETIZAÇÃO
            </h2>
          </div>

          <p className="text">
            Primeiramente apresentamos a ALPHA E ÔMEGA AMBIENTAL, que se trata
            de uma empresa de desentupimentos de qualquer natureza e de controle
            de pragas urbanas, registrada e legalizada nos órgãos compententes,
            portanto sempre pensando em nossos clientes e no meio ambiente, com
            serviços de desentupimentos em geral e dedetização com produtos
            autorizados pela ANVISA (Agência Nacional de Vigilância Sanitária).
          </p>

          <h2 className="text2"> Atendemos:</h2>

          <div className="gallery">
            <div className="item">
              <figure className="image">
                <img src={icon1} alt="Residências" />
              </figure>

              <p className="text">Residências</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon2} alt="Condomínios" />
              </figure>

              <p className="text">Condomínios</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon3} alt="Chácaras" />
              </figure>

              <p className="text">Chácaras</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon4} alt="Comércios" />
              </figure>

              <p className="text">Comércios</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon5} alt="Hospitais" />
              </figure>

              <p className="text">Hospitais</p>
            </div>

            <div className="item">
              <figure className="image">
                <img src={icon6} alt="Escolas" />
              </figure>

              <p className="text">Escolas</p>
            </div>
          </div>

          <CtaWpp text={"Solicite um orçamento"} />
        </article>
      </section>
    </header>
  );
};

export default Header;
