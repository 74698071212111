import { useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState(props.text);

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+5515996650653?text=Preciso%20de%20um%20orçamento."
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
