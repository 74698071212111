import { useState } from "react";
import "./styles.css";

import icon from "../../assets/icontel.png";

const CtaTel = () => {
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState("(15) 99665-0653");

  return (
    <button id="ctaTel">
      <a
        href="tel:996650653"
      >
        <figure>
          <img src={icon} alt="Ícone do Telefone" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaTel;
