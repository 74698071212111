import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";
import img4 from "../../assets/img4.png";
import img5 from "../../assets/img5.png";
import img6 from "../../assets/img6.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">ENTUPIU? Não quebre!</h2>

          <p className="text">
            Desde uma simples pia entupida a um grande sistema de tubulação
            industrial, a ALPHA E ÔMEGA AMBIENTAL está preparada para resolver!
          </p>

          <p className="text2">
            Além dos melhores equipamentos do mercado, contamos com um time de
            profissionais altamente qualificados e comprometidos em entregar
            sempre os melhores resultados aos nossos clientes.
          </p>

          <CtaWpp text={"Solicite um orçamento"} />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">SERVIÇOS DE DESENTUPIMENTO E LIMPEZA</h2>

          <div className="gallery">
            <figure className="item">
              <img src={img1} alt="Desentupimento de Pias" />
            </figure>

            <figure className="item">
              <img src={img2} alt="Desentupimento de Ralos" />
            </figure>

            <figure className="item">
              <img src={img3} alt="Desentupimento de Vaso Sanitário" />
            </figure>

            <figure className="item">
              <img src={img4} alt="Desentupimento de Esgotos" />
            </figure>

            <figure className="item">
              <img src={img5} alt="Hidrojateamento" />
            </figure>

            <figure className="item">
              <img src={img6} alt="Limpeza de caixa d’água" />
            </figure>
          </div>

          <p className="text">
            O desentupimento profissional tem como meta recuperar, triturar e
            remover os agentes causadores do entupimento, deixando o interior
            das tubulações limpas e perfeitas para o uso. O mesmo procedimento é
            válido para o desentupimento de pia, desentupimento de esgoto,
            desentupimento de vaso sanitário, residencial, comercial e
            industrial.
          </p>

          <CtaWpp text={"Solicite um orçamento"} />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">SERVIÇOS DE DEDETIZAÇÃO</h2>

          <div className="gallery">
            <figure className="item">
              <img src={about3_1} alt="DESRATIZAÇÃO" />
            </figure>

            <figure className="item">
              <img src={about3_2} alt="DESCUPINIZAÇÃO" />
            </figure>

            <figure className="item">
              <img src={about3_3} alt="DESINSETIZAÇÃO" />
            </figure>
          </div>

          <div className="text">
            De acordo com o cenário atual em que vivemos é muito importante
            mantermos nossas casas limpas e sempre que possível fazermos uma
            SANITIZAÇÃO para eliminarmos bactéria, fungo, vírus, microrganismos
            que estão sempre presente. Como conclusão não perca tempo e agende
            agora mesmo uma visita totalmente gratuita, para que você se previna
            e cuide das pessoas que moram ou visitam sua residência ou empresa.
          </div>

          <CtaWpp text={"Solicite um orçamento"} />
        </div>
      </article>
    </section>
  );
};

export default About;
